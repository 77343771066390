.mainView {
  background-color: #1B232D !important;
  border-radius: 5px !important;
  width: 70% !important;
}

.editTableView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.editTableText {
  font-family: InterMedium !important;
  color: #fff !important;
  font-size: 13px !important;
}

.borderBottomLine {
  border: 1px solid #202C38 !important;
}

.textFieldView {
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.plusMinusIcon {
  cursor: pointer !important;
  background-color: #2B3745 !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 40px !important;
}

.lineView {
}

.saveBtnView {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.saveBtnText {
  font-family: InterMedium !important;
  color: #43ACE3 !important;
  font-size: 13px !important;
}
