.textInputLabel {
    font-size: 14.5px;
    font-family: InterSemiBold !important;
}

.minutesText {
    font-size: 15px;
    font-family: InterMedium !important;
}

.btnMainView {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.saveBtn {
    cursor: pointer !important;
    text-transform: capitalize !important;
    font-family: InterSemiBold !important;
    font-size: 15px !important;
    border-radius: 9px !important;
    height: 44px;
    width: 100%;
}

.editBtn {
    cursor: pointer !important;
    text-transform: capitalize !important;
    font-family: InterSemiBold !important;
    font-size: 15px !important;
    border-radius: 9px !important;
    height: 44px;
    width: 100%;
}
