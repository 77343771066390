.pageTitle {
  color: #ffffff;
  font-size: 21px !important;
  font-family: InterSemiBold !important;
}

.backBtn {
  width: fit-content !important;
  cursor: pointer;
  line-height: 0 !important;
}

.dateText {
  color: #ffffff;
  font-size: 15px !important;
  font-family: InterMedium !important;
}

.addBtnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.addBtnText {
  font-size: 14px;
  font-family: InterSemiBold !important;
}

.cardView {
  border-radius: 7px;
}

.titleText {
  color: #ffffff;
  font-size: 13px !important;
  font-family: InterRegular !important;
}

.totalPriceText {
  color: #ffffff;
  font-size: 19px !important;
  font-family: InterMedium !important;
}

.dataView {
  display: flex;
  flex-direction: column;
  height: 100px;
}

.contentView {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.listView {
  display: flex;
  flex-direction: row;
}

.listDataView {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.listLabelText {
  color: #ffffff;
  font-size: 13px !important;
  font-family: InterRegular !important;
  word-break: break-all;
}

.bullet {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #dddddd;
}

.listValueText {
  color: #ffffff;
  font-size: 13px !important;
  font-family: InterRegular !important;
  text-align: right;
  width: 80px;
}

.viewMoreText {
  color: #ffffff;
  font-size: 13px !important;
  font-family: InterRegular !important;
  text-decoration: underline;
  cursor: pointer;
}

.reportTitleText {
  color: #ffffff;
  font-size: 21px !important;
  font-family: InterSemiBold !important;
}

.contentReportView {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.reportView {
  display: flex;
  flex-direction: row;
}

.reportDataView {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reportLabelText {
  color: #ffffff;
  font-size: 16px !important;
  font-family: InterRegular !important;
  word-break: break-all;
}

.reportValueText {
  color: #ffffff;
  font-size: 16px !important;
  font-family: InterRegular !important;
  text-align: right;
  width: 80px;
}
