.searchAddBtnView {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
}

.searchView {
  flex: 1;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.addCustomerBtn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.addCustomerBtnTxt {
  font-size: 13px !important;
  font-family: InterBold !important;
}

.customerListView {
  overflow: auto;
}

.customerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  cursor: pointer;
  min-height: 60px;
}

.customerTxtView {
  width: 25%;
  padding-right: 14px;
}

.customerPhNoView {
  width: 15%;
  padding-right: 14px;
}

.customerEmailView {
  width: 30%;
  padding-right: 14px;
}

.customerAddressView {
  width: 30%;
  padding-right: 14px;
}

.customerTxt {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  line-height: 14px !important;
  word-wrap: break-word;
}

.prevVisitCountView {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
  width: fit-content;
}

.prevVisitCountText {
  font-size: 9px !important;
  font-family: InterBold !important;
}

.prevVisitSpendText {
  font-size: 9px !important;
  font-family: InterBold !important;
}

@media screen and (min-width: 1920px) {
  .addCustomerBtnTxt {
    font-size: 18px !important;
  }

  .customerView {
    min-height: 70px;
  }

  .customerTxt {
    font-size: 19px !important;
    line-height: 19px !important;
  }

  .prevVisitCountText {
    font-size: 14px !important;
  }

  .prevVisitSpendText {
    font-size: 14px !important;
  }
}
