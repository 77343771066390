.addBtnText {
	font-size: 12px;
	font-family: InterSemiBold !important;
}

.statusBtn {
	height: 100%;
	border-radius: 8px;
	width: 100px;
	cursor: pointer
}
