.main-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffffff;
  padding: 2px;
  width: fit-content;
}

.main-view .add-sub-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  cursor: pointer;
}

.main-view .add-sub-text {
  font-family: InterBold;
  font-size: 18px;
  color: #000000;
}

.main-view .count-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
}

.main-view .count-text {
  font-family: InterSemiBold;
  font-size: 14px;
  color: #ffffff;
}
