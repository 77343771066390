.main-view {
  height: calc(100vh - 200px);
  overflow: auto;
  border-radius: 10px;
  background-color: #202a38;
  padding: 8px 0;
  margin-top: 8px;
}

.cat-name-view {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
}

.cat-name-view .cat-name-text {
  color: #ffffff;
  font-size: 16px;
  font-family: InterSemiBold;
}

.main-view .checkbox-main-view {
  width: 100%;
  margin: 0 0 8px 0;
  padding: 0 8px;
}

.checkbox-main-view .checkbox-view {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #313e53;
}

.checkbox-main-view .menu-text {
  color: #ffffff;
  font-size: 14px;
  font-family: InterMedium;
}
