.cartMainView {
  flex: 1;
  overflow: auto;
}

.addCourseMainView {
}

.addCourseTitleView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 45px;
}

.addCourseTitleText {
  font-family: InterBold !important;
  font-size: 17px !important;
}

.addCoursePlusText {
  font-family: InterSemiBold !important;
  font-size: 30px !important;
}

.addCourseMenuView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 48px;
}

.addCourseView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-height: 48px;
}

.addCourseText {
  font-size: 17px !important;
  font-family: InterBold !important;
}

.orderPriceSubView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subPriceView {
  flex: 1;
}

.totalPriceText {
  font-family: InterBold !important;
}

.totalPrice {
  font-family: InterBold !important;
}

.bottomMainView {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomView {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  padding-right: 5px;
  padding-left: 5px;
}

.bottomText {
  font-family: InterBold !important;
  text-align: center;
  text-transform: uppercase !important;
}

@media screen and (min-width: 1920px) {
  .addCourseMenuView {
    min-height: 70px;
  }

  .addCourseView {
    min-height: 85px;
  }

  .addCourseTitleView {
    min-height: 85px;
  }

  .addCourseText {
    font-size: 22px !important;
  }

  .addCourseTitleText {
    font-size: 22px !important;
  }

  .addCoursePlusText {
    font-size: 35px !important;
  }

  .orderPriceSubView {
    min-height: 55px;
  }

  .totalPriceText {
    font-size: 24px !important;
  }

  .totalPrice {
    font-size: 24px !important;
  }
}
