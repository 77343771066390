.addBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 7px;
  cursor: pointer;
}

.addBtnText {
  font-size: 16px !important;
  font-family: InterSemiBold !important;
}

.updateBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 70px;
  border-radius: 7px;
  cursor: pointer;
  padding-right: 6px;
  padding-left: 6px;
}

.catListView {
  display: flex;
  flex-wrap: wrap;
}

.catListNameView {
  border-radius: 15px;
}

.catListNameText {
  font-size: 12px !important;
  font-family: InterMedium !important;
}
.updateBtnText {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
}

.courseListMainView {
}

.courseTitle {
  font-size: 16px !important;
  font-family: InterSemiBold !important;
}

.courseView {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 45px;
  border-radius: 10px;
  cursor: pointer;
}

.course-move-icon {
  line-height: 0;
  padding: 0 10px;
}

.course-name-view {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.course-name-view .course-name-text {
  color: #ffffff;
  font-size: 17px;
  font-family: InterSemiBold;
  padding: 12px;
}

.actionBtn {
  cursor: pointer;
}
