.nameText {
  font-family: InterBold !important;
}

.emailText {
  font-family: InterMedium !important;
}

.seatsMainView {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seatsView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 13px !important;
}
