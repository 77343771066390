.bgView {
  background: linear-gradient(
    120.96deg,
    #04060a 2.56%,
    #02070d 46.52%,
    #010407 96.97%
  );
}

.bgView2 {
  background-color: #eeeeee;
}

.mainView {
  height: 100vh;
  z-index: 2;
}

.logoView {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.poweredByText {
  font-family: InterSemiBold !important;
  font-size: 10px !important;
  text-align: center;
  padding-top: 5px;
}

.pinLoginBg {
  position: absolute;
  inset: 25px;
  z-index: 1;
}

.leftView {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
}

.rightView {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
}

.logoImgView {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoImg {
  width: 35%;
}

.btnView {
  width: 60%;
}

.clockInOutBtnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.clockInBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
}

.clockInBtnText {
  font-family: InterSemiBold !important;
  text-align: center;
}

.clockOutBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
}

.clockOutBtnText {
  font-family: InterSemiBold !important;
  text-align: center;
}

.noteBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
}

.noteBtnText {
  font-family: InterSemiBold !important;
  text-align: center;
}

.logoutView {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 45px;
  left: 60px;
  bottom: 60px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 3;
}

.logoutText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.timeText {
  font-family: InterBold !important;
  font-size: 20px !important;
  line-height: 20px;
  text-align: center;
}

.dateText {
  font-family: InterSemiBold !important;
  font-size: 14px !important;
  text-align: center;
  opacity: 0.63;
}

.pinMainView {
  display: flex;
  align-items: center;
  min-height: 30px;
}

.pinView {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.calcView {
}

@media screen and (min-width: 1920px) {
  .clockInBtnView {
    height: 80px;
  }

  .clockOutBtnView {
    height: 80px;
  }

  .noteBtnView {
    height: 80px;
  }
}
