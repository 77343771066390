.boxView {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  /*border-style: dashed !important;*/
}

.rectangleBox {
}

.selectedBox {
  background-color: #bedaf9 !important;
}

.nonSelectedBox {
  background-color: #bedaf9 !important;
}

.circleBox {
}

.chairBox {
  position: absolute !important;
}

.leftChairBox {
  border-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rightChairBox {
  border-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.topChairBox {
  border-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.bottomChairBox {
  border-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
