.cartItemDataView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.cartItemNameText {
  font-family: InterBold !important;
}

.cartItemPriceTextView {
  width: 55px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cartItemPriceText {
  font-family: InterBold !important;
  text-align: right;
  font-size: 32px !important;
}

.listView {
  overflow: auto;
}

.modifierListView {
  border-radius: 19px;
}

.modifierTitleView {
  display: flex;
}

.modifierTitleTxt {
  flex: 1;
  font-family: InterBold !important;
  font-size: 14px !important;
}

.modifierPriceTxt {
  font-family: InterBold !important;
  font-size: 16px !important;
}

.addNoteView {
  border-radius: 19px;
}

.addNoteLabel {
  font-family: InterBold !important;
  font-size: 16px !important;
}

.btnView {
  display: flex;
  flex-direction: row;
}

.voidBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.voidBtnText {
  font-family: InterBold !important;
  font-size: 15px !important;
}

.closeBtn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeBtnText {
  font-family: InterBold !important;
  font-size: 15px !important;
}

@media screen and (min-width: 1920px) {
  .cartItemNameText {
    font-size: 42px !important;
    line-height: 42px !important;
  }

  .cartItemPriceTextView {
    width: 80px;
  }

  .cartItemPriceText {
    font-size: 42px !important;
  }

  .modifierTitleTxt {
    font-size: 19px !important;
  }

  .modifierPriceTxt {
    font-size: 19px !important;
  }

  .addNoteLabel {
    font-size: 21px !important;
  }

  .voidBtnText {
    font-size: 22px !important;
  }

  .closeBtnText {
    font-size: 22px !important;
  }
}
