.topLeftView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.statusFilterMainView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.statusFilterListView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  width: fit-content;
}

.statusFilterView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  min-width: 110px;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.statusFilterText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}
