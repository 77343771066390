.modelView {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainView {
  border-radius: 10px;
  overflow: hidden;
}

.saveBtn {
  cursor: pointer;
  font-family: InterBold !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  border-radius: 9px;
  height: 44px;
  width: 150px;
}

.cancelBtn {
  cursor: pointer;
  font-family: InterBold !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  border-radius: 9px;
  height: 44px;
  width: 150px;
  margin-right: 10px !important;
}
