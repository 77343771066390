.mainView {
  width: 100%;
}

.cartItemDataView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 25px;
}

.cartItemNameText {
  font-family: InterBold !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.cartDiscountText {
  font-family: InterSemiBold !important;
  font-size: 12px !important;
  line-height: 12px !important;
}

.cartItemBtnPriceView {
  display: flex;
  align-items: center;
}

.cartItemModText {
  font-family: InterSemiBold !important;
  font-size: 12px !important;
  line-height: 13px;
}

.cartItemNoteText {
  font-family: InterSemiBold !important;
  font-size: 12px !important;
  line-height: 13px;
}

.cartItemPriceTextView {
  min-width: 55px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cartItemPriceText {
  font-family: InterBold !important;
  text-align: right;
  font-size: 14px !important;
}

@media screen and (min-width: 1920px) {
  .cartItemDataView {
    min-height: 50px;
  }

  .cartItemNameText {
    font-size: 21px !important;
    line-height: 21px !important;
  }

  .cartDiscountText {
    font-size: 17px !important;
    line-height: 17px !important;
  }

  .cartItemModText {
    font-size: 17px !important;
    line-height: 18px;
  }

  .cartItemNoteText {
    font-size: 17px !important;
    line-height: 18px;
  }

  .cartItemPriceTextView {
    min-width: 80px;
  }

  .cartItemPriceText {
    font-size: 19px !important;
  }
}
