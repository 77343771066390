@font-face {
  font-family: "InterBlack";
  src: url("../fonts/inter/Inter-Black.ttf");
}

@font-face {
  font-family: "InterExtraBold";
  src: url("../fonts/inter/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: "InterBold";
  src: url("../fonts/inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "InterSemiBold";
  src: url("../fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "InterLight";
  src: url("../fonts/inter/Inter-Light.ttf");
}

@font-face {
  font-family: "InterRegular";
  src: url("../fonts/inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "InterMedium";
  src: url("../fonts/inter/Inter-Medium.ttf");
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0 !important;
  overflow: visible !important;
  padding-right: 0 !important;
  background-color: #000000 !important;
}

.MuiAppBar-root {
  padding-right: 0 !important;
}

.login-main-grid {
  height: 100%;
}

.login-logo-section {
  display: flex;
  justify-content: center;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  height: 100px;
}

.login-grid-height {
  height: 100%;
}

.login-form-main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
}

.login-logo {
  align-self: center;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.menu-list-main-grid {
  border-radius: 10px;
  width: 100%;
  margin-bottom: 12px;
  padding: 10px;
}

.menu-list-expand-vertical-icon-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}

.menu-list-image-grid {
  display: flex;
  align-items: center;
}

.menu-list-image {
  border-radius: 10px;
  width: 100%;
  height: 80px;
}

.menu-list-main-data-grid {
  padding-left: 15px;
}

.menu-list-sub-data-grid {
  display: flex;
  align-items: center;
}

.menu-list-price-data-grid {
  display: flex;
  align-items: center;
}

.menu-list-horizontal-dot-icon-grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 10px;
}

.menu-grid-list-view-grid {
  border-radius: 7px;
  padding: 5px;
  height: 31px;
}

.menu-grid-view-icon {
  margin-right: 12px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.menu-arrow-icon-grid {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  padding: 12px;
  margin-top: 10px;
}

.pagination-main-grid {
  display: flex;
  justify-content: center;
}

.pagination-grid {
  border-radius: 20px;
  padding: 2px;
  margin-bottom: 10px;
}

/*add restaurant info*/
.restaurant-first-data-grid {
  margin-top: 15px !important;
}

.restaurant-second-data-grid {
  margin-top: 6px !important;
}

.restaurant-data-grid {
  margin-top: 35px !important;
}

.restaurant-label {
  color: #aaa;
  font-size: 12px;
}

.restaurant-main-option {
  flex-direction: row !important;
}

.restaurant-option {
  margin-right: 40px !important;
}

/*restaurant images upload*/

.image-upload-grid {
  display: flex;
  flex-direction: column !important;
  text-align: center;
  align-items: center;
  height: fit-content;
  border-style: dashed !important;
  border-radius: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.image-drag-text {
  font-size: 12px !important;
  margin-bottom: 10px !important;
}

.image-upload-text {
  font-size: 12px !important;
  margin-bottom: 20px !important;
}

.image-browse-grid {
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 12px;
  padding: 3px 20px 3px 20px;
}

.image-browse-text {
  font-size: 12px !important;
  /*font-weight: bold !important;*/
}

.image-list-grid {
  align-items: center;
}

/*restaurant-opening-hours*/

.day-label {
  width: 120px;
  font-size: 13px;
}

/*restaurant-location*/

.location-pin-main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
}

.location-pin-box {
  display: flex;
  background-color: rgba(222, 79, 84, 0.3);
  justify-content: center;
  align-items: center;
  border: 1px solid #ffdad9;
  border-radius: 35px;
  width: 70px;
  height: 70px;
}

.gm-ui-hover-effect {
  display: none !important;
}

.hover-top-cut {
  width: 48px;
  height: 48px;
  background-color: #ffe342;
  border-radius: 0 0 30px;
  position: absolute;
  top: -48px;
  right: 0;
  z-index: 2;
}

.hover-top-white {
  width: 48px;
  height: 48px;
  position: absolute;
  top: -48px;
  right: 0;
}

.hover-bottom-cut {
  width: 48px;
  height: 48px;
  background-color: #ffe342;
  border-radius: 0 30px 0 0;
  position: absolute;
  bottom: -48px;
  right: 0;
  z-index: 3;
}

.hover-bottom-white {
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: -48px;
  right: 0;
}

.sidebar-not-active-hover:hover {
  border-radius: 30px;
  z-index: 5;
}

.add-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 13px !important;
  width: 100%;
  padding: 10px;
  min-height: 550px;
}

/*menu-add-item*/

.menu-image-upload {
  display: flex;
  flex-direction: column !important;
  text-align: center !important;
  align-items: center !important;
  height: 250px !important;
  border-style: dashed !important;
  border-radius: 10px;
  position: relative;
}

.browse-grid {
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 12px;
  padding: 3px 20px 3px 20px;
}

.menu-price-box {
  height: 15px;
  margin-left: 5px;
  margin-right: 10px;
}

.restaurant-swipe .swiper-wrapper {
  align-items: flex-end;
}

.restaurant-swipe .swiper-slide {
  width: 60% !important;
  height: 80px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  padding: 2px 10px;
}

.restaurant-swipe .swiper-slide.swiper-slide-active {
  width: 60% !important;
  height: 90px;
  background-color: #fff;
  margin-bottom: 5px;
  padding: 1px 10px 1px 15px;
}

.rest-bottom-tab .TabUnstyled-root:nth-child(0) {
  margin-left: -1px;
}

.rest-bottom-tab .TabUnstyled-root:nth-child(1) {
  margin-left: -1px;
  margin-right: -1px;
}

.rest-bottom-tab .TabUnstyled-root:nth-child(2) {
  margin-right: -1px;
}

#mainMapView .gm-style .gm-style-iw-t::after {
  background: none;
  box-shadow: none;
}

#mainMapView .gm-style-iw {
  top: -30px !important;
}

#mainMapView .gm-style-iw .map-location-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#mainMapView .gm-style-iw .address-div {
  /*width: 150px;*/
  padding: 1.3px;
  border-radius: 2px;
}

#mainMapView .gm-style-iw .rest-name {
  font-size: 10px;
  font-family: InterBold;
  text-align: center;
}

#mainMapView .gm-style-iw .address-name {
  font-size: 10px;
  font-family: InterMedium;
  text-align: center;
}

#mainMapView .gmnoprint a,
.gmnoprint span {
  display: none !important;
}

#mainMapView .gmnoprint div {
  background: none !important;
}

#mainMapView .gmnoprint {
  display: none !important;
}

#mainMapView .gm-style-cc {
  display: none !important;
}

#mainMapView .gm-style-iw-tc {
  display: none !important;
}

#mainMapView .gm-style > div > div > a > div > img {
  display: none !important;
}

.catOrderListView {
  user-select: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.catOrderDragged {
  z-index: 10000;
}

.catMenuSearchTextInput .css-qtxfjx-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 0.5px solid #ccc !important;
}

.menu-arrow-icon-grid1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-color: #fff;*/
  /*border: 1px solid #fff;*/
  border-radius: 10px;
  height: 56px;
}

.menuListOrderDragged {
  z-index: 10000;
}

.menu-order-list-view {
  width: 100%;
}

.search-event-input,
.search-menu-input {
  position: relative;
  z-index: 4;
}

.login-bg-image {
  background-image: linear-gradient(0deg, #333333 0%, transparent 74%);
}

/*AddListFloor Style Starts*/

#floorMgmtDivId .main-view {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

#floorMgmtDivId
  .floor-name-view
  .MuiSwitch-switchBase.Mui-disabled:not(.Mui-checked)
  + .MuiSwitch-track {
  background-color: #eeeeee;
}

#floorMgmtDivId
  .floor-name-view
  .MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  color: #ffffff;
}

#floorMgmtDivId .floor-name-view {
  display: flex;
  align-items: center;
  height: 45px;
}

#floorMgmtDivId .floor-takeaway-label {
  color: #fff;
  font-size: 8px;
  font-family: InterSemiBold;
  text-align: center;
}

#floorMgmtDivId .floor-takeaway-value {
  font-size: 12px;
  font-family: InterBold;
  text-align: center;
}

#floorMgmtDivId .floor-name-takeaway-view {
  display: flex;
  align-items: center;
  flex: 1;
}

#floorMgmtDivId .floor-name-input {
  display: flex;
  align-items: flex-end;
  height: 45px;
}

#floorMgmtDivId .floor-name-text {
  flex: 1;
  font-size: 15px !important;
  font-family: InterSemiBold !important;
}

#floorMgmtDivId .table-num-text {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
}

#floorMgmtDivId .from-to-main-view {
  display: flex;
  min-height: 80px;
}

#floorMgmtDivId .from-to-view {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

#floorMgmtDivId .from-to-text {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
}

#floorMgmtDivId .from-to-val-view {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  width: 36px;
  height: 36px;
}

#floorMgmtDivId .from-to-val-text {
  font-size: 15px !important;
  font-family: InterSemiBold !important;
}

#floorMgmtDivId .btn-main-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

#floorMgmtDivId .btn-view {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 38px;
}

#floorMgmtDivId .btn-text {
  font-size: 12px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

/*AddListFloor Style Ends*/

#tableSetUp .rect {
  border: unset;
  border-style: unset;
}

#tableSetUp .resizable-handler {
  cursor: pointer !important;
  background-color: #374453 !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  height: 24px !important;
  width: 24px !important;
  padding: 4px !important;
  background-size: 9px 9px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-image: url("../images/resize.svg") !important;
  bottom: -25px !important;
  right: -25px !important;
}

#tableSetUp .gblcbk .br {
  position: absolute !important;
  bottom: -18px;
  right: -16px;
}

#tableSetUp .rotate {
  position: absolute !important;
  bottom: -27px !important;
  left: -10px !important;
  top: unset !important;
}

#tableSetUp .rotate i {
  cursor: pointer !important;
  background-color: #374453 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  height: 24px !important;
  width: 24px !important;
  padding: 5px !important;
  background-size: 12px 12px !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-image: url("../images/rotate.svg") !important;
}

.MuiMultiSectionDigitalClockSection-root {
    border: 1px solid #fff;
}

.course-order-dragged {
    z-index: 1000;
}
