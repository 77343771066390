.mainView {
  border-radius: 15px;
  overflow: hidden;
}

.mainSubView {
}

.leftView {
  position: relative;
}

.addGiftCardTitle {
  display: flex;
  align-items: center;
}

.addGiftCardTitleText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}

.priceBtnList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.priceBtnView {
  border-radius: 10px;
  cursor: pointer;
}

.priceBtnText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-transform: capitalize;
}

.historyBtnView {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.historyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: fit-content;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.historyBtnText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.calcMainView {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.calcView {
  border-radius: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calcText {
  font-family: InterSemiBold !important;
  text-align: center;
}

.giftCardAmtView {
  left: 0;
}

.giftCardAmtText {
  font-size: 15px !important;
  font-family: InterBold !important;
}

.giftCardAmt {
  font-size: 30px !important;
  font-family: InterBold !important;
}

.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.addBtnText {
  font-size: 18px !important;
  font-family: InterBold !important;
  text-align: center;
}
