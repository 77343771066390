.loadingView {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tableListView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tableMainView {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tableView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12%;
  cursor: pointer;
}

.tableNoView {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34%;
  height: 34%;
  border-radius: 50%;
}

.tableNoText {
  text-align: center;
  font-family: InterSemiBold !important;
}

.tableOrderMin {
  text-align: center;
  font-family: InterBold !important;
}

.tableOrderItems {
  border-radius: 10px;
  text-align: center;
  width: 85%;
  font-family: InterSemiBold !important;
}

.floorListView {
  border-radius: 10px;
  overflow: hidden;
}

.floorMainView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.floorView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75%;
}

.floorOrderCount {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.floorOrderCountText {
  text-align: center;
  font-family: InterSemiBold !important;
}

.floorText {
  text-align: center;
  font-family: InterBold !important;
}

.borderLine {
  width: 30%;
}
