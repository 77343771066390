.headerView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemListView {
  border-radius: 10px;
}

.optionsListView {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tipChangePrintView {
  display: flex !important;
  flex-direction: column !important;
}

.billingActionsButton {
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.billingActionsButtonText {
  font-family: InterBold !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.courseHead {
  align-items: center;
  display: flex;
  width: 100%;
  padding: 10px 7px;
  height: 45px;
}

.courseHeadTxt {
  font-size: 16px !important;
  font-family: InterSemiBold !important;
}

.menuItemName {
  font-family: InterBold !important;
}

.paymentMethodsHead {
  font-family: InterBold !important;
  font-size: 18px !important;
}

.paymentMethodView {
  min-height: 50px;
  align-items: center;
}

.paymentMethodsName {
}

.tipChangeView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  border-radius: 15px;
}

.paymentSubView {
  align-items: center;
  min-height: 40px;
}

.printReceiptView {
  align-items: center;
  min-height: 60px;
}

.totalAmountHead {
  font-family: InterBold !important;
  font-size: 20px !important;
}

.totalPrice {
  font-family: InterBold !important;
  font-size: 24px !important;
}

.payBtnText {
  font-family: InterBold !important;
  font-size: 18px !important;
  text-transform: uppercase;
}

.totalAmountInner {
  font-family: InterMedium !important;
  font-size: 16px !important;
}

.paymentList {
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.extraPaymentItem {
  position: relative;
  border-radius: 5px;
  width: 90px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.extraPaymentItemName {
  font-family: InterSemiBold !important;
  font-size: 12px !important;
}

.extraPaymentItemPrice {
  font-family: InterBold !important;
  font-size: 19px !important;
}

.paidIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
}

.cancelRemoveBtnView {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.btnView {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50%;
}

.btnText {
  font-family: InterSemiBold !important;
  text-align: center;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.paidMainView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.paidView {
  border: 2px solid #148e20;
  border-radius: 10px;
  min-width: 80px;
}

.paidMethod {
  font-family: InterSemiBold !important;
  font-size: 13px !important;
  text-transform: uppercase !important;
  text-align: center;
  color: #fff;
}

.paidValue {
  font-family: InterSemiBold !important;
  text-align: center;
  font-size: 16px !important;
  color: #fff;
}

@media screen and (min-width: 1920px) {
  .billingActionsButtonText {
    font-size: 21px !important;
  }

  .courseHead {
    height: 60px;
  }

  .courseHeadTxt {
    font-size: 21px !important;
  }

  .paymentMethodsHead {
    font-size: 22px !important;
  }

  .paymentMethodView {
    min-height: 80px;
  }

  .paymentMethodsName {
    font-size: 21px !important;
  }

  .tipChangeView {
    height: 100px;
    border-radius: 15px;
  }

  .paymentSubView {
    min-height: 55px;
  }

  .printReceiptView {
    min-height: 80px;
  }

  .totalAmountHead {
    font-size: 26px !important;
  }

  .totalPrice {
    font-size: 30px !important;
  }

  .payBtnText {
    font-size: 30px !important;
  }

  .totalAmountInner {
    font-size: 21px !important;
  }

  .extraPaymentItem {
    width: 130px;
    height: 120px;
  }

  .extraPaymentItemPrice {
    font-size: 25px !important;
  }

  .btnText {
    font-size: 22px !important;
  }

  .paidView {
    border: 3px solid #148e20;
    border-radius: 10px;
    min-width: 100px;
  }

  .paidMethod {
    font-size: 19px !important;
  }

  .paidValue {
    font-size: 22px !important;
  }
}
