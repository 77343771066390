.catMenuListView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.noCourseText {
  color: white;
  font-family: InterSemiBold !important;
  font-size: 17px !important;
}

.menuMainView {
  display: flex;
}

.menuImgSubView {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
}

.menuSubView {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  min-height: 70px;
  border-radius: 12px;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menuBgShade {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
  /*background: linear-gradient(*/
  /*  270.25deg,*/
  /*  rgba(0, 0, 0, 0.6) 0.21%,*/
  /*  rgba(0, 0, 0, 0.6) 22.43%,*/
  /*  rgba(0, 0, 0, 0.6) 47.12%,*/
  /*  rgba(0, 0, 0, 0.419875) 68.3%,*/
  /*  rgba(0, 0, 0, 0) 99.78%*/
  /*);*/
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.menuTextView {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 10px;
}

.menuText {
  font-family: InterBold !important;
  text-align: center;
  text-transform: uppercase !important;
}

.catBottomView {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
}

.catListMainView {
  display: flex;
  flex-direction: column;
}

.catListSubView {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;
}

@media screen and (min-width: 1920px) {
  .menuSubView {
    min-height: 140px;
  }

  .catListSubView {
    height: 90px;
  }
}
