.textInputMainView {
  margin-top: 16px !important;
}

.textInputLabelView {
  display: flex;
  align-items: center;
}

.textInputLabel {
  font-size: 14.5px !important;
  font-family: InterSemiBold !important;
}

.btnMainView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px !important;
}

.saveBtn {
  cursor: pointer;
  text-transform: capitalize !important;
  font-family: InterSemiBold !important;
  font-size: 15px !important;
  border-radius: 9px !important;
  height: 44px !important;
  width: 100% !important;
}
