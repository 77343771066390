.mainView {
  background: linear-gradient(
    120.96deg,
    #04060a 2.56%,
    #02070d 46.52%,
    #010407 96.97%
  );
}

.subView {
  height: 100vh;
  z-index: 2;
  align-items: center;
  justify-content: center;
}

.loginView {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.logoView {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}

.poweredByText {
  font-family: InterSemiBold !important;
  font-size: 10px !important;
  text-align: center;
  padding-top: 5px;
}

.pinLoginBg {
  position: absolute;
  inset: 25px;
  z-index: 1;
}
