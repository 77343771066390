.mainView {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.headerView {
  display: flex;
}

.tabView {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.tableListMainView {
  overflow: auto;
}

.tableListView {
  display: flex;
  flex-wrap: wrap;
}

.tableMainView {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableView {
  border-radius: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.tableTextView {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableText {
  font-family: InterSemiBold !important;
  text-align: center;
}

.submitBtn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.submitBtnText {
  font-size: 16px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  .tabText {
    font-size: 19px !important;
  }

  .submitBtnText {
    font-size: 22px !important;
  }
}
