.modelView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.mainView {
  border-radius: 3px;
  overflow: hidden;
}

.dataText {
  font-family: InterSemiBold !important;
  text-align: center;
  font-size: 16px !important;
}

.btnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.submitBtn {
  cursor: pointer;
  text-transform: capitalize;
  font-family: InterSemiBold !important;
  font-size: 15px !important;
  border-radius: 9px;
  height: 44px;
  width: 100%;
}
