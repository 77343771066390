.mainView {
  border-radius: 13px;
  overflow: hidden;
}

.topLeftView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnView {
  display: flex;
  cursor: pointer;
}

.searchMainView {
  position: relative;
  width: 100%;
}

.customerListView {
  position: absolute;
  box-shadow: 0px 0px 15px 0px #00000026;
  background-color: white;
  border-radius: 8px;
  width: 100%;
  z-index: 5;
  margin-top: 5px;
  overflow: auto;
  max-height: 300px;
}

.customerView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  cursor: pointer;
}

.customerText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}

.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.addBtnText {
  font-size: 16px !important;
  font-family: InterBold !important;
  text-align: center;
}
