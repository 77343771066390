.main-view {
    flex-direction: column;
    margin-top: 8px;
}

.textInputLabel {
    font-size: 14.5px !important;
    font-family: InterSemiBold !important;
}

.toggle-switch-label {
    display: flex;
    align-items: center;
    font-size: 14.5px !important;
    font-family: InterSemiBold !important;
}

.saveBtn {
    cursor: pointer !important;
    text-transform: capitalize !important;
    font-family: InterSemiBold !important;
    font-size: 15px !important;
    border-radius: 9px !important;
    height: 44px;
    width: 20%;
}

.social-medias-list-view {
    justify-content: space-between;
    border: 1px solid #AAA;
    border-radius: 20px;
    margin-bottom: 16px;
    padding: 16px 24px;
}

.main-view .add-entry-btn-view {
    justify-content: flex-end;
    margin-top: 5px;
}

.add-entry-btn-view .add-entry {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    width: 130px;
    height: 42px;
    border-radius: 2px;
    padding: 0 18px;
    color: #ffffff;
    font-size: 14px;
    font-family: InterSemiBold;
    cursor: pointer;
}
