.headerView {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.backBtn {
  cursor: pointer;
  width: fit-content;
  line-height: 0.5;
}

.floorListView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btnView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  width: 120px;
  height: 44px;
}

.btnText {
  color: #fff;
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}
