.mainView {
  border: 1px solid #e1e1e1;
  box-shadow: 0px 0px 20px 0px #0000001a;
  border-radius: 10px;
  overflow: hidden;
}

.titleView {
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
}

.back-btn {
  cursor: pointer;
  margin-right: 8px;
}

.titleText {
  flex: 1;
  font-size: 14px !important;
  font-family: InterBold !important;
}

.mainView .delete-btn,
.mainView .delete-btn:hover {
  background-color: #d42323;
  color: #ffffff;
  font-size: 12px;
  font-family: InterMedium;
  height: 30px;
  text-transform: capitalize;
}

.modifierList {
  padding: 0 10px;
}

.modifierView {
  border: 1px solid #e1e1e1;
  box-shadow: 0px 0px 20px 0px #0000001a;
  border-radius: 10px;
}

.modifierTitleMainView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modifierTitleView {
  display: flex;
  flex: 1;
}

.modifierTitleText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}

.requiredView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.requiredText {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
}

.modifierItemList {
}

.itemView {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.itemNameView {
  display: flex;
  flex: 1;
}

.circle-icon {
  margin-right: 8px;
}

.itemNameText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}

.itemRightView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemPriceView {
}

.itemPriceText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}

.actionBtnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.actionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 10px;
  cursor: pointer;
}

.actionBtnText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}
