.mainView {
  flex: 1 !important;
}

.leftView {
  flex: 1 !important;
}

.leftSubView {
  flex: 1 !important;
  border-radius: 5px !important;
  width: 100%;
  height: 100%;
}

.newElementText {
  font-size: 15px !important;
  font-family: InterMedium !important;
  color: #fff !important;
}

.dragAndDropText {
  font-size: 13px !important;
  font-family: InterMedium !important;
  color: #ccc !important;
}

.borderBottomLine {
  border: 1px solid #555 !important;
  width: 100% !important;
}

.shapeText {
  font-size: 13px !important;
  font-family: InterMedium !important;
  color: #58687A !important;
}

.rightView {
  flex: 1;
  border-radius: 5px !important;
  position: relative !important;
  overflow: hidden !important;
}

.floorView {
  border-radius: 5px !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.floorText {
  color: #fff !important;
  font-family: InterSemiBold !important;
  font-size: 14px !important;
}

.floorBtnView {
  flex: 1;
}

.addFloorBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  width: 50px !important;
  height: 50px !important;
}

.saveBtnView {
  display: flex;
  background-color: #DD5903;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 100px !important;
  height: 50px !important;
  cursor: pointer;
}

.saveBtnViewText {
  color: #fff !important;
  font-family: InterSemiBold !important;
  font-size: 15px !important;
}
