.backBtn {
  cursor: pointer;
  width: fit-content;
}

.cardView {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  border-radius: 10px;
  width: 30% !important;
  min-height: 250px;
}

.cardTopView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardIconView {
  flex: 1;
}

.userCount {
  color: #000000;
  font-size: 25px !important;
  font-family: InterBold !important;
  text-align: right;
}

.userText {
  color: #000000;
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: right;
}

.descText {
  color: #000000;
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  margin-bottom: 6px !important;
}

.viewBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 44px;
}

.viewBtnText {
  color: #fff;
  font-size: 14px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.itemCardView {
  display: flex;
  flex-direction: column !important;
  background: linear-gradient(
    150deg,
    rgb(255 255 255 / 20%),
    rgb(0 0 0) 40%
  ) !important;
  border-radius: 10px;
  border: 1px solid #444444;
  width: 30% !important;
  min-height: 250px;
}

.topViewedText {
  color: #fff;
  font-size: 20px !important;
  font-family: InterSemiBold !important;
}

.itemView {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #444444;
}

.itemNameView {
  display: flex;
  flex: 1;
}

.itemName {
  color: #fff;
  font-size: 13px !important;
  font-family: InterMedium !important;
}

.itemCountView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemCount {
  color: #aaaaaa;
  font-size: 13px !important;
  font-family: InterMedium !important;
}
