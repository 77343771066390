.orderTypeFilterMainView {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dateFilterMainView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}

.orderTypeFilterListView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: fit-content;
  position: relative;
}

.orderTypeFilterView {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-width: 110px;
  height: 40px;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.dateFilterView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  height: 100%;
}

.orderTypeFilterText {
  font-size: 12px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.orderDateTitleText {
  font-size: 14px !important;
  font-family: InterBold !important;
  text-align: left;
}

.orderList {
  width: 100%;
}

.orderListView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 7px;
  min-height: 60px;
  cursor: pointer;
}

.orderDataSubView {
  width: 32%;
}

.orderSubView {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.orderPriceSubView {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.orderPriceView {
  min-width: 90px;
}

.orderIdText {
  font-size: 13px !important;
  font-family: InterBold !important;
}

.orderDateText {
  font-size: 12px !important;
  font-family: InterSemiBold !important;
}

.orderTypeView {
  border-radius: 5px;
  padding: 2px 8px;
}

.orderTypeText {
  font-size: 11px !important;
  font-family: InterSemiBold !important;
}

.orderNameText {
  font-size: 13px !important;
  font-family: InterBold !important;
}

.orderPriceText {
  font-size: 14px !important;
  font-family: InterSemiBold !important;
}

.orderPriceValText {
  font-size: 13px !important;
  font-family: InterBold !important;
}
