.navbarRightView {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.tableBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.tableBtnText {
  font-size: 17px !important;
  font-family: InterSemiBold !important;
  text-align: center !important;
}

.logoutBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

@media screen and (min-width: 1920px) {
  .tableBtn {
    height: 100%;
  }

  .tableBtnText {
    font-size: 25px !important;
  }

  .logoutBtn {
    height: 100%;
  }
}
