.calcMainView {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.calcSubView {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.calcView {
  border-radius: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calcText {
  font-family: InterSemiBold !important;
  text-align: center;
}
