.mainView {
  position: relative !important;
}

.chairBox {
  position: absolute !important;
}

.leftChairBox {
  border-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rightChairBox {
  border-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.topChairBox {
  border-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.bottomChairBox {
  border-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.tableNumberView {
}

.tableNumberText {
  font-family: InterSemiBold !important;
}

.minsView {
}

.minsText {
  font-family: InterMedium !important;
  text-align: center;
}

.courseNameView {
  background-color: #000 !important;
  border-radius: 14px !important;
  padding-left: 13px !important;
  padding-right: 13px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.courseNameText {
  font-family: InterMedium !important;
  color: #fff !important;
}

.iconView {
  cursor: pointer !important;
  height: 24px !important;
  width: 24px !important;
  border-radius: 50% !important;
  padding: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: absolute !important;
  top: -10px !important;
}

.editIconView {
  background-color: #e86d6d !important;
  right: -10px !important;
}

.deleteIconView {
  background-color: #374453 !important;
  left: -10px !important;
}

.editPopUpView {
  position: absolute !important;
  z-index: 1000 !important;
}
