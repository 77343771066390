.switch-input-view {
  margin-right: 10px;
  min-width: 150px;
  min-height: 70px;
}

.switch-input-view .toggle-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.btn-main-View {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.btn-main-View .save-btn {
  cursor: pointer;
  text-transform: capitalize;
  font-family: InterSemiBold;
  font-size: 15px;
  border-radius: 9px;
  height: 44px;
  width: 100%;
}

.btn-main-View .cancel-btn {
  cursor: pointer;
  text-transform: capitalize;
  font-family: InterSemiBold;
  font-size: 15px;
  border-radius: 9px;
  height: 44px;
  width: 100%;
}
