.menu-main-view {
  background-color: #313e53;
  border-radius: 15px;
  padding: 8px 16px;
}

.header-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
}

.menu-main-view .header-text {
  color: #ffffff;
  font-size: 16px;
  font-family: InterSemiBold;
}

.footer-view {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 45px;
}

.menu-main-view .save-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  height: 40px;
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
  font-family: InterSemiBold;
  color: #ffffff;
  text-transform: capitalize;
}

.menu-main-view .save-btn:hover {
  background-color: #000000;
}
