.modelView {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.mainView {
  border-radius: 10px;
  overflow: hidden;
}

.headerView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.titleText {
  font-family: InterBold !important;
  font-size: 20px !important;
}

.saveBtn {
  cursor: pointer;
  font-family: InterBold !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  border-radius: 9px;
  height: 44px;
  width: 150px;
}

.cancelBtn {
  cursor: pointer;
  font-family: InterBold !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
  border-radius: 9px;
  height: 44px;
  width: 150px;
}
