.headerMainView {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerView {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.headerText {
  font-size: 18px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.headerValText {
  font-size: 18px !important;
  font-family: InterBold !important;
  margin-left: 2px;
}

@media screen and (min-width: 1920px) {
  .headerText {
    font-size: 23px !important;
  }

  .headerValText {
    font-size: 23px !important;
  }
}
