.mainView {
  position: relative;
  width: 100%;
  height: 46px;
  border-radius: 12px;
  overflow: hidden;
}

.inputFile {
  position: absolute;
  z-index: 1;
}

.label {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.uploadView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.uploadView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}

.uploadText {
  flex: 1;
  font-family: InterSemiBold !important;
}

.close {
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 17px !important;
  font-family: InterBlack !important;
  cursor: pointer;
  z-index: 3;
  right: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.errorText {
  font-size: 10px !important;
  font-family: InterMedium !important;
}
