.mainView {
  cursor: pointer;
  min-height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cartItemDataView {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.cartItemNameText {
  font-family: InterBold !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.cartItemQtyText {
  font-size: 17px !important;
  line-height: 17px !important;
  margin-right: 8px;
}

.cartItemBtnPriceView {
  display: flex;
  align-items: center;
}

.cartItemModText {
  font-family: InterSemiBold !important;
  font-size: 12px !important;
  line-height: 13px;
}

.cartItemNoteText {
  font-family: InterSemiBold !important;
  font-size: 12px !important;
  line-height: 13px;
}

.cartItemPriceTextView {
  min-width: 55px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cartItemPriceText {
  font-family: InterBold !important;
  text-align: right;
  font-size: 15px !important;
}

.cartPlacedOrder {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #148e20;
}

@media screen and (min-width: 1920px) {
  .mainView {
    min-height: 80px;
  }

  .cartItemNameText {
    font-size: 19px !important;
    line-height: 19px !important;
  }

  .cartItemQtyText {
    font-size: 22px !important;
    line-height: 22px !important;
  }

  .cartItemModText {
    font-size: 17px !important;
    line-height: 18px;
  }

  .cartItemNoteText {
    font-size: 17px !important;
    line-height: 18px;
  }

  .cartItemPriceTextView {
    min-width: 70px;
  }

  .cartItemPriceText {
    font-size: 20px !important;
  }
}
