.mainView {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.headerView {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.noOfTextView {
  flex: 1;
}

.noOfText {
  font-size: 11px !important;
  font-family: InterSemiBold !important;
  text-align: left;
}

.coversText {
  font-size: 17px !important;
  font-family: InterBold !important;
  text-align: right;
}

.calcListView {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calcMainView {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.calcView {
  border-radius: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30%;
}

.calcText {
  font-size: 17px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.submitBtn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.submitBtnText {
  font-size: 16px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  .noOfText {
    font-size: 17px !important;
  }

  .coversText {
    font-size: 22px !important;
  }

  .calcText {
    font-size: 22px !important;
  }

  .submitBtnText {
    font-size: 21px !important;
  }
}
