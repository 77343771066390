.navbarView {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
  height: 100%;
}

.voidBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.voidBtnText {
  font-family: InterBold !important;
  font-size: 16px !important;
  text-align: center;
  text-transform: uppercase !important;
}

@media screen and (min-width: 1920px) {
  .voidBtnText {
    font-size: 26px !important;
  }
}
