.addBtnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 9px;
  cursor: pointer;
  margin-left: 16px;
  padding: 10px 30px;
}

.addBtnText {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
  margin-left: 1px;
}
