.mainView {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.formView {
  overflow: auto;
}

.inputLabel {
  font-family: InterSemiBold !important;
}

.errorText {
  font-size: 10px !important;
  font-family: InterMedium !important;
}

.eventMainView {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.eventHeader {
  display: flex;
  flex-direction: row;
}

.eventHeaderText {
  font-size: 12px !important;
  font-family: InterBold !important;
}

.eventListMainView {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  height: 48px;
}

.eventListView {
  flex: 1;
}

.eventText {
  font-size: 11px !important;
  font-family: InterBold !important;
}

.eventDateView {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eventDateText {
  font-size: 11px !important;
  font-family: InterBold !important;
}

.addEventBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  height: 45px;
}

.addEventBtnTxt {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
}

.prevVisitMainView {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.prevVisitHeader {
  display: flex;
  flex-direction: row;
}

.prevVisitTextView {
  flex: 1;
}

.prevVisitText {
  font-size: 12px !important;
  font-family: InterSemiBold !important;
}

.prevVisitCountView {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 17px;
}

.prevVisitCountText {
  font-size: 11px !important;
  font-family: InterBold !important;
}

.prevVisitSpendText {
  font-size: 11px !important;
  font-family: InterBold !important;
}

.prevVisitListView {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
}

.prevVisitDateView {
  flex: 1;
}

.prevVisitDateText {
  font-size: 11px !important;
  font-family: InterBold !important;
}

.prevVisitPriceText {
  font-size: 11px !important;
  font-family: InterBold !important;
}

.btnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.submitBtn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.submitBtnText {
  font-size: 13px !important;
  font-family: InterBold !important;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  .errorText {
    font-size: 15px !important;
  }

  .eventHeaderText {
    font-size: 17px !important;
  }

  .eventListMainView {
    height: 60px;
  }

  .eventText {
    font-size: 16px !important;
  }

  .eventDateText {
    font-size: 16px !important;
  }

  .addEventBtnTxt {
    font-size: 18px !important;
  }

  .addEventBtn {
    height: 55px;
  }

  .prevVisitText {
    font-size: 17px !important;
  }

  .prevVisitCountText {
    font-size: 16px !important;
  }

  .prevVisitSpendText {
    font-size: 16px !important;
  }

  .prevVisitDateText {
    font-size: 16px !important;
  }

  .prevVisitPriceText {
    font-size: 16px !important;
  }

  .submitBtnText {
    font-size: 20px !important;
  }
}
