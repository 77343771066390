.courseListView {
  display: flex !important;
  flex-wrap: wrap;
}

.courseView {
  display: flex !important;
  flex-wrap: nowrap;
}

.menuText {
  font-family: InterBold !important;
  text-align: center;
  word-break: break-all !important;
  text-transform: uppercase !important;
}
