.modifierItemView {
}

.withExtraMainView {
  display: flex;
  justify-content: space-between;
}

.withExtraView {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 33%;
  cursor: pointer;
}

.withExtraTxt {
  font-family: InterSemiBold !important;
  font-size: 14px !important;
  text-transform: capitalize;
}

.modifierMainView {
  display: flex;
  flex-wrap: wrap;
}

.modifierView {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.modifierTxt {
  font-family: InterSemiBold !important;
  font-size: 14px !important;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  .withExtraView {
    height: 70px;
  }

  .withExtraTxt {
    font-size: 19px !important;
  }

  .modifierView {
    height: 70px;
  }

  .modifierTxt {
    font-size: 19px !important;
  }
}
