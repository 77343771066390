.mainView {
  border-radius: 13px;
  overflow: hidden;
}

.topDateView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.timeListMainView {
  display: flex;
  flex-direction: row;
}

.addBtnView {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 15px 0px #00000026;
  border-radius: 8px;
  min-width: 115px;
  min-height: 95px;
  cursor: pointer;
}

.timeListView {
  box-shadow: 0px 0px 15px 0px #00000026;
  border-radius: 8px;
  min-width: 115px;
  min-height: 95px;
  cursor: pointer;
}

.timeText {
  font-size: 15px !important;
  font-family: InterBold !important;
  text-align: center;
}

.limitReserveView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  padding: 0 6px;
}

.limitReserveText {
  font-size: 13px !important;
  font-family: InterSemiBold !important;
  text-align: center;
}

.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.addBtnText {
  font-size: 16px !important;
  font-family: InterBold !important;
  text-align: center;
}
