.social-media-view {
  justify-content: space-between;
  border-bottom: 2px solid #aaa;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.social-media-view .text-input-label {
  color: #ffffff;
  font-size: 14.5px;
  font-family: InterSemiBold;
}

.social-media-view .delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  width: 120px;
  height: 45px;
  border-radius: 8px;
  padding: 0 18px;
  color: #ffffff;
  font-size: 14px;
  font-family: InterSemiBold;
  cursor: pointer;
}

.social-media-view .error-text {
  color: #cd0000;
  font-size: 10px;
  font-family: InterMedium;
}
