.dataView {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.btnMainView {
  display: flex;
  flex-direction: row;
}

.sureText {
  font-family: InterBold !important;
  font-size: 25px !important;
  white-space: nowrap;
}

.sureDescText {
  font-family: InterSemiBold !important;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.nameText {
  font-family: InterBold !important;
  font-size: 14px !important;
  line-height: 16px;
  text-align: center;
}

.btnView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
}

.btnText {
  font-size: 16px;
  font-family: InterSemiBold !important;
}
