.cardMainView {
  position: relative;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.backBtn {
  position: absolute;
  width: fit-content !important;
  cursor: pointer;
  line-height: 0 !important;
  top: 10px;
  left: 10px;
}

.cardView {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 350px;
  border-radius: 7px;
  cursor: pointer !important;
}

.itemText {
  color: #ffffff;
  font-size: 1rem !important;
  font-family: InterBold !important;
  text-align: center;
}
