.tabBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize !important;
  font-family: InterBold !important;
  font-size: 15px;
  border-radius: 5px 5px 0px 0px !important;
  width: 150px;
  height: 42px;
}

.titleText {
  font-family: InterBold !important;
  text-align: left;
  font-size: 18px;
}
