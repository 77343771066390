.modalHeaderView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.modalHeaderText {
  font-size: 15px !important;
  font-family: InterBold !important;
}

@media screen and (min-width: 1920px) {
  .modalHeaderView {
    height: 100px;
  }

  .modalHeaderText {
    font-size: 20px !important;
  }
}
