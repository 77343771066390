.modalHeaderView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.modalHeaderText {
  font-size: 17px !important;
  font-family: InterBold !important;
}

.btnView {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.submitBtn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.submitBtnText {
  font-size: 13px !important;
  font-family: InterBold !important;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  .modalHeaderView {
    height: 100px;
  }

  .modalHeaderText {
    font-size: 20px !important;
  }

  .submitBtnText {
    font-size: 20px !important;
  }
}
