.resetView {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.resetText {
  color: #1e90ff !important;
  font-family: InterSemiBold !important;
  font-size: 15px !important;
  margin-left: 1px !important;
  text-decoration: underline;
}
