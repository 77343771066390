.nameText {
  font-family: InterBold !important;
}

.emailText {
  font-family: InterMedium !important;
}

.statusView {
  border-radius: 8px;
  text-align: center;
  padding: 4px 5px;
  font-size: 12px !important;
  width: 100px;
}
