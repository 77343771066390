.mainView {
  display: flex;
  flex-direction: column !important;
  /*justify-content: space-between;*/
  width: 100%;
}

.topView {
  border-radius: 10px;
  display: flex;
}

.tabMainView {
  display: flex;
  flex-direction: column !important;
  /*justify-content: space-between;*/
  width: 100%;
}

.tabView {
  /*border-radius: 9px;*/
  cursor: pointer;
}

.tabText {
  font-family: InterBold !important;
  font-size: 18px !important;
  text-align: center;
  text-transform: uppercase !important;
}

.discountBtnMainView {
  /*display: flex;*/
  /*flex-direction: row;*/
  width: 100%;
}

.calculatorView {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.calcMainView {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.calcView {
  border-radius: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30%;
}

.calcText {
  font-family: InterSemiBold !important;
  text-align: center;
}

.coversText {
  font-size: 17px !important;
  font-family: InterBold !important;
  text-align: left;
}

.coversVal {
  font-size: 32px !important;
  font-family: InterBold !important;
  text-align: left;
}

.amountText {
  font-size: 16px;
  font-family: InterBold !important;
  text-align: right;
}

.amountVal {
  font-size: 25px !important;
  font-family: InterBold !important;
  text-align: right;
}

.btnView {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.btnText {
  font-family: InterBold !important;
  text-align: center;
  font-size: 18px !important;
  text-transform: uppercase !important;
}

@media screen and (min-width: 1920px) {
  .tabText {
    font-size: 28px !important;
  }

  .coversText {
    font-size: 25px !important;
  }

  .coversVal {
    font-size: 42px !important;
  }

  .amountText {
    font-size: 22px;
  }

  .amountVal {
    font-size: 42px !important;
  }

  .btnText {
    font-size: 23px !important;
  }
}
