.nameBox {
  min-width: 110px;
}

.actionBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionBtn {
  border-radius: 8px;
  padding: 5px 20px;
  cursor: pointer;
}

.cancelledTag {
  padding: 0px 20px;
  border-radius: 8px;
}

.statusTag {
  font-family: InterSemiBold !important;
  font-size: 14px;
}
